.main-menu {
  background: #ffffff;
  border-right: 1px solid #eeeeee;
  position: fixed;
  top: 81px;
  bottom: 0;
  /* height: 100%; */
  left: 0;
  width: 60px;
  overflow: hidden;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.customselect {
  background: #f8f9fd !important;
  color: #4d4d4d !important;
  font-size: 12px !important;
}

.customselect .pmselect .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0;
  vertical-align: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: "\f078";
  font: normal normal normal 14px/1 FontAwesome;
  font-weight: 100;
  font-size: 10px;
  margin-right: 10px;
}

.customselect .css-1s2u09g-control {
  background: transparent;
  border: none;
  min-height: 20px !important;
  max-width: 120px !important;
  width: 130px !important;
}

.customselect .css-6j8wv5-Input {
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.customselect .css-tlfecz-indicatorContainer {
  padding: 0 5px 0 0 !important;
}

.customselect .css-1gtu0rj-indicatorContainer {
  padding: 0 !important;
}

.customselect .css-1pahdxg-control {
  background: transparent;
  border: none;
  min-height: 20px !important;
  box-shadow: none !important;
  max-width: 120px !important;
  width: 130px !important;
}

.customselect .css-1pahdxg-control:hover {
  border: none !important;
  box-shadow: none !important;
}

.customselect .css-1pndypt-Input {
  padding: 0 !important;
  margin: 0 !important;
}

.customselect .bootstrap-select > select {
  position: unset !important;
  margin: 0;
  display: unset !important;
}

.customselect .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: auto !important;
}

.customselect .tableid {
}

.customselect .tableid .dropdown-toggle::after {
  display: none !important;
}

.customselect .tableid .dropdown-toggle {
  padding-right: 0 !important;
  width: 90px !important;
}

.customselect .pmselect {
}

.customselect .pmselect .dropdown-toggle {
  width: 120px !important;
  border-left: 1px solid #e8ecff;
  border-radius: 0;
  padding-left: 5px;
  font-weight: 600;
}

.customselect .css-tlfecz-indicatorContainer {
  color: #872ff7 !important;
}

.customselectsearch {
  position: relative;
}

.customselectsearch .customselectsearch-list {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.customselectsearch .customselectsearch-list .list-group-item {
  padding: 5px 10px;
  cursor: pointer;
}

.custom-input-sleact {
  background: #f8f9fd;
  border: none;
  border-radius: 0px 5px 5px 0;
  color: hsl(0deg 0% 19%);
  font-size: 12px;
  border-left: 1px solid #6f6f6f !important;
  padding-left: 5px;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.contant_box_404 {
  margin-top: -50px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
